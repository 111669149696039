import { Modal } from "components"
import { useEffect, useRef, useState } from "react"
import { Cropper, ImageRestriction, getTransformedImageSize, retrieveSizeRestrictions } from "react-advanced-cropper"

import "react-advanced-cropper/dist/style.css"
import "styles/cropper.css"
import toast from "react-hot-toast"
import imageCompression from "browser-image-compression"

const CameraUpIcon = ({ className, stroke }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} width={24} height={24} viewBox="0 0 24 24" strokeWidth={stroke ?? 1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 20h-7a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v3.5"></path>
            <path d="M12 16a3 3 0 1 0 0 -6a3 3 0 0 0 0 6z"></path>
            <path d="M19 22v-6"></path>
            <path d="M22 19l-3 -3l-3 3"></path>
        </svg>
    )
}

const usePrevious = (value) => {
    const ref = useRef()

    useEffect(() => {
        ref.current = value
    }, [value])

    return ref.current
}

const CropImage = ({ src, onSubmit }) => {
    const [current, setCurrent] = useState()

    const cropperRef = useRef(null)

    const handleChange = (data) => {
        // setCurrent(data.getCanvas())
    }

    const handleSubmit = () => {
        onSubmit(cropperRef.current.getCanvas())
    }

    const boundaryProps = (boundary) => {
        return {
            width: boundary.clientWidth,
            height: boundary.clientHeight
        }
    }

    const stencilProps = {
        aspectRatio: 16/9,
        grid: true
    }

    const defaultSize = ({ imageSize, visibleArea }) => {
        return {
            width: (visibleArea || imageSize).width,
            height: (visibleArea || imageSize).height
        }
    }

    return (
        <div className="mt-8 space-y-8">
            {/* <div className="flex w-full"> */}
            <Cropper boundaryProps={{ boundaryProps }} stencilProps={stencilProps} defaultSize={defaultSize} ref={cropperRef} onChange={handleChange} src={src} />
            {/* </div> */}
            <div className="flex items-center space-x-2 text-xs">
                <button onClick={handleSubmit} type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>Crop</span>
                </button>
            </div>
        </div>
    )
}

const createBlob = (dataURL) => {
    var BASE64_MARKER = ';base64,'
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
        var parts = dataURL.split(',')
        var contentType = parts[0].split(':')[1]
        var raw = decodeURIComponent(parts[1])
        return new Blob([raw], { type: contentType })
    }
    var parts = dataURL.split(BASE64_MARKER)
    var contentType = parts[0].split(':')[1]
    var raw = window.atob(parts[1])
    var rawLength = raw.length

    var uInt8Array = new Uint8Array(rawLength)

    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
    }

    return new Blob([uInt8Array], { type: contentType });
}

const InputImage = ({ onChange, aspect = "video", disabled, id, name, error, src, alt = "https://placehold.co/1600x900/F5F5F5/404040?font=source-sans-pro&text=Upload%20image%20here" }) => {
    const [selectedImage, setSelectedImage] = useState()
    const [selectedImageView, setSelectedImageView] = useState(src)
    const [preview, setPreview] = useState(src)
    const [cropped, setCropped] = useState(src)

    const [isOpenCropModal, setIsOpenCropModal] = useState(false)
    const [loading, set_loading] = useState(false)

    const prevSelectedImage = usePrevious(selectedImage)

    // Watch selected image, update preview
    useEffect(() => {
        if (selectedImage !== prevSelectedImage) {
            onChange(selectedImage)

            if (!selectedImage) {
                setPreview(undefined)
            } else {
                const objectURL = URL.createObjectURL(selectedImage)
                setPreview(objectURL)

                // Clean up memory when component unmounted
                return () => URL.revokeObjectURL(objectURL)
            }
        }
    }, [selectedImage])

    const handleInputChange = async(e) => {
        set_loading(true)
        if (!e.target.files || e.target.files.length === 0 || !e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
            // setSelectedImage(undefined)
            toast.error("Please select an image.")
        } else {
            const image = e.target.files[0]
            console.log(image,"image")
            if(image.size > 10195488){
                toast.error("File size is more than 10Mb.")
            }else{
                const options = {
                    maxSizeMB: 0.5,
                    useWebWorker: true,
                    alwaysKeepResolution:false
                }
                var imageName = new Date().getTime()+".png";
                const compressedFile = await imageCompression(image, options);
                const fileData = new File(
                    [compressedFile],
                    imageName,
                { type :'image/jpeg'})
                    console.log(fileData,"fileData")
                // setIsOpenCropModal(true)
                setSelectedImage(fileData)
                setSelectedImageView(URL.createObjectURL(fileData))
                e.target.value = null
            }
        }
        set_loading(false)
    }

    const handleCropImage = async(value) => {

        setCropped(value.toDataURL())
        onChange(createBlob(value.toDataURL()))

        closeCropModal()

        // toast.success(JSON.stringify(value))
    }

    const closeCropModal = () => {
        setIsOpenCropModal(false)
    }

    return (
        <>
            <input disabled={disabled} type="file" className="hidden" hidden id={id} name={name} onChange={handleInputChange} />
            <div className="relative mt-1 select-none">
                { loading ? 
                <div role="status" className="mt-2 flex justify-center">
                    <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span class="sr-only">Loading...</span>
                </div>
                : <img className={`object-cover w-full h-auto border rounded-lg aspect-${aspect} ring ${error ? 'ring-red-100' : 'ring-neutral-100'}`} src={selectedImageView ?? alt} />}
                <label htmlFor={id} className={`absolute inset-0 flex items-center justify-center w-full group ${disabled ? '' : 'cursor-pointer'}`}>
                    <div class={`px-4 py-2 text-sm font-semibold text-neutral-700 transition bg-white border border-neutral-300 rounded-lg shadow-sm pointer-events-none bg-opacity-70 ${disabled ? '' : 'group-hover:bg-opacity-90 group-active:border-neutral-300 group-active:ring group-active:ring-neutral-100'}`}>
                        <CameraUpIcon className={`w-6 h-6 transition transform ${disabled ? '' : 'group-active:scale-90'}`} stroke={1.5} />
                    </div>
                </label>
            </div>
            <Modal scaleAnimation={false} size="md" isOpen={isOpenCropModal} onClose={closeCropModal} title={`Crop Image`} content={<CropImage onSubmit={(value) => handleCropImage(value)} src={preview ?? alt} />} />
        </>
    )
}

export default InputImage