import { useState } from "react"

import lodash from "lodash"
import deepdash from "deepdash"
import { lang } from "config"
import Label from "components/forms/label"
import Input from "components/forms/input"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import InputImage from "components/forms/input-image"
// import { convertToRaw } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import draftToHtml from 'draftjs-to-html';
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Heading, List, Font } from 'ckeditor5';
// import { SlashCommand } from 'ckeditor5-premium-features';

import 'ckeditor5/ckeditor5.css';
import Checkbox from "components/forms/checkbox"

export default function Form({ data, errors, onSubmit }) {
    const _ = deepdash(lodash)

    const id = data?.id
    const [name, set_name] = useState(data?.name ?? null)
    const [address, set_address] = useState(data?.address ?? null)
    const [type, set_type] = useState(data?.type ?? null)
    const [contact_name, set_contact_name] = useState(data?.contact_name ?? null)
    const [contact_email, set_contact_email] = useState(data?.contact_email ?? null)
    const [contact_number, set_contact_number] = useState(data?.contact_number ?? null)
    const [latitude, set_latitude] = useState(data?.latitude ?? null)
    const [longitude, set_longitude] = useState(data?.longitude ?? null)
    const [image, set_image] = useState(data?.image ?? null)

    const handleSubmit = (e) => {
        e.preventDefault()
        var email_validation = contact_email ? emailValidation(contact_email) : true;

        if(!email_validation){
            toast.error("Email format is invalid")
        }else if(name === null || name === ''){
            toast.error("Name Outlet Service Required!")
        }else if(address === null || address === ''){
            toast.error("Address Outlet Service Required!")
        }else if(contact_name === null || contact_name === ''){
            toast.error("Contact Name Required!")
        }else if(contact_email === null || contact_email === ''){
            toast.error("Contact Email Required!")
        }else if(contact_number === null || contact_number === ''){
            toast.error("Contact Number Required!")
        }else if(latitude === null || latitude === ''){
            toast.error("Latitude Required!")
        }else if(longitude === null || longitude === ''){
            toast.error("Longitude Required!")
        }else{
            if (!nProgress.isStarted()) {
                onSubmit({
                    id,
                    name,
                    address,
                    image,
                    contact_name,
                    contact_email,
                    contact_number,
                    latitude,
                    longitude
                })
            } else {
                toast.error("Previous action is loading")
            }
        }
    }
    
    const emailValidation = ( email ) => {

        // don't remember from where i copied this code, but this works.
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        if ( (re.test(email)) ) {
            return true
        }else if(email != "") {
            return false
        }
    }

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <Label htmlFor="name" value={'Outlet Name'} />
                                        <Input placeholder="Input name" onChange={(e) => set_name(e.target.value)} value={name} id="name" error={errors.name} />
                                    </div>
                                    <div>
                                        <Label htmlFor="contact_name" value={'Contact Name'} />
                                        <Input placeholder="Input Contact Name" onChange={(e) => set_contact_name(e.target.value)} value={contact_name} id="contact_name" error={errors.name} />
                                    </div>
                                    <div>
                                        <Label htmlFor="contact_email" value={'Contact Email'} />
                                        <Input placeholder="Input Contact Email" onChange={(e) => set_contact_email(e.target.value)} value={contact_email} id="contact_email" error={errors.email} />
                                    </div>
                                    <div>
                                        <Label htmlFor="contact_number" value={'Contact Number'} />
                                        <Input placeholder="Input Contact Number" type={'number'} onChange={(e) => set_contact_number(e.target.value)} value={contact_number} id="contact_number" error={errors.name} />
                                    </div>
                                    <div>
                                        <Label htmlFor="latitude" value={'Latitude'} />
                                        <textarea type={'textarea'} placeholder="Input Latitude" onChange={(e) => set_latitude(e.target.value)} value={latitude} id="latitude" className="border-neutral-200 placeholder:text-neutral-500 file:border-solid file:border file:px-3 file:text-xs file:border-neutral-200 file:py-1 file:rounded-full block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200"/>
                                    </div>
                                    <div>
                                        <Label htmlFor="longitude" value={'Longitude'} />
                                        <textarea type={'textarea'} placeholder="Input Longitude" onChange={(e) => set_longitude(e.target.value)} value={longitude} id="longitude" className="border-neutral-200 placeholder:text-neutral-500 file:border-solid file:border file:px-3 file:text-xs file:border-neutral-200 file:py-1 file:rounded-full block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200"/>
                                    </div>
                                    <div>
                                        <Label htmlFor="image" value={'Image'} />
                                        <InputImage onChange={(value) => set_image(value)} aspect="video" id="image" name="image" src={image} error={errors.image} alt="https://placehold.co/400x400/F5F5F5/404040?font=source-sans-pro&text=400x400" />
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 mt-4">
                                    <div>
                                        <Label htmlFor="address" value={'Outlet Address'} />
                                        <textarea type={'textarea'} placeholder="Input Address" onChange={(e) => set_address(e.target.value)} value={address} id="address" className="border-neutral-200 placeholder:text-neutral-500 file:border-solid file:border file:px-3 file:text-xs file:border-neutral-200 file:py-1 file:rounded-full block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${_.isEmpty(data) ? lang.create : lang.update}`}</span>
                </button>
            </div>
        </form>
    )
}