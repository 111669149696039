import axios from "axios";

export const newAbortSignal = (timeout) => {
    const abortController = new AbortController();
    setTimeout(() => abortController.abort(), timeout || 0);

    return abortController.signal;
}
  
const axiosInterceptor = () => {
    axios.interceptors.response.use(undefined, (err) => {
        const { config, message } = err;
        if (!config || !config.retry) {
        return Promise.reject(err);
        }
        // retry while Network timeout or Network Error
        if (!(message.includes("canceled") || message.includes("timeout") || message.includes("Network Error"))) {
        return Promise.reject(err);
        }
        config.retry -= 1;
        const delayRetryRequest = new Promise((resolve) => {
        setTimeout(() => {
            // console.log("retry the request", config.url);
            resolve();
        }, config.retryDelay || 1000);
        });
        return delayRetryRequest.then(() => axios(config));
    });
}
  
export const postData = async (url, dataOptions) => {

    return sendAxios('POST', url, dataOptions)

}
  
export const getData = async (url, paramOptions) => {

    return sendAxios('GET', url, paramOptions)

}

const sendAxios = async (method, url, methodOptions) => {
  
    axiosInterceptor()
  
    const options = {
        signal: newAbortSignal(process.env.REACT_APP_TIMEOUT),
        timeout: process.env.REACT_APP_TIMEOUT,
        method: method,
        url: url,
        headers: {
          'content-type': 'application/json',
        }, 
        data: method === 'POST' ? methodOptions : {}, 
        params: method === 'GET' ? methodOptions : {}, 
    };
  
    // console.log('inside ' + url + ' -- before sent');
  
    try {
    //   console.log(options)
      const response = await axios.request(options)
      console.log('response ' + url + ' with result:');
      return response
    } catch (err) {
      console.log('response ' + url + ' with error:');
      console.log('err.message', err.message);
      return false;
    }
}