import { useState } from "react"

import lodash from "lodash"
import deepdash from "deepdash"
import { lang } from "config"
import Label from "components/forms/label"
import Input from "components/forms/input"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import useBrandRepo from "repositories/brand"

export default function Form({ data, errors, onSubmit }) {
    const _ = deepdash(lodash)

    const id = data?.id
    const [brand_code, set_brand_code] = useState(data?.brand_code ?? null)
    const [name, set_name] = useState(data?.name ?? null)
    const { data: brands } = useBrandRepo({
        page:1,
        paginate:99
    })

    const handleSubmit = (e) => {
        e.preventDefault()

        if(name === null || name === ''){
            toast.error("Name Required!")
        }else if(brand_code === null || brand_code === ''){
            toast.error("Brand Code Required!")
        }else{
            if (!nProgress.isStarted()) {
                onSubmit({
                    id,
                    name,
                    brand_code,
                })
            } else {
                toast.error("Previous action is loading")
            }
        }
    }

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <Label htmlFor="brand_code" value="Brand Code" />
                                        <select onChange={(e) => set_brand_code(e.target.value)} value={brand_code} name="brand_code" id="brand_code" className={`border-neutral-200 block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                            <option value={null} selected disabled>-- Brand Code? --</option>
                                            {
                                                brands && brands.data.map((data,index) => 
                                                    <option value={data.brand_code} key={index}>{data.brand_name}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div>
                                        <Label htmlFor="name" value={'Name'} />
                                        <Input placeholder="Input Name" onChange={(e) => set_name(e.target.value)} value={name} id="name" error={errors.name} />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>

            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${_.isEmpty(data) ? lang.create : lang.update}`}</span>
                </button>
            </div>
        </form>
    )
}