import { useNavigate } from 'react-router-dom';
// import ImgCheck from '../assets/Check.png';

const Finish =()=>{
    
    return(
        <form>
            <div className="space-y-12">
                <div className="py-12">
                    <div className='flex justify-center pt-5'>
                        <img src={require('../assets/Logo.png')} width={'25%'} height={'25%'} alt='logo' className='go object-center'/>
                    </div>
                    <div className='text-center'>
                        <h2 className='font-bold color-lvl-4'>Payment Received</h2>
                        <p>You can leave this page</p>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default Finish