import { useState } from "react"

import lodash from "lodash"
import deepdash from "deepdash"
import { lang } from "config"
import Label from "components/forms/label"
import Input from "components/forms/input"
import nProgress from "nprogress"
import toast from "react-hot-toast"

export default function Form({ data, errors, onSubmit }) {
    const _ = deepdash(lodash)

    const id = data?.id
    const [brand_code, set_brand_code] = useState(data?.brand_code ?? null)
    const [brand_name, set_brand_name] = useState(data?.brand_name ?? null)

    const handleSubmit = (e) => {
        e.preventDefault()

        if(brand_code === null || brand_code === ''){
            toast.error("Brand Code Required!")
        }else if(brand_name === null || brand_name === ''){
            toast.error("Brand Name Required!")
        }else{
            if (!nProgress.isStarted()) {
                onSubmit({
                    brand_code,
                    brand_name,
                })
            } else {
                toast.error("Previous action is loading")
            }
        }
    }

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <Label htmlFor="brand_code" value={'Brand Code'} />
                                        <Input disabled={data?.brand_code} placeholder="Input Brand Code" onChange={(e) => set_brand_code(e.target.value)} value={brand_code} id="brand_code" error={errors.name} />
                                    </div>
                                    <div>
                                        <Label htmlFor="brand_name" value={'Brand Name'} />
                                        <Input placeholder="Input Brand Name" onChange={(e) => set_brand_name(e.target.value)} value={brand_name} id="brand_name" error={errors.name} />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>

            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${_.isEmpty(data) ? lang.create : lang.update}`}</span>
                </button>
            </div>
        </form>
    )
}