import { useState } from "react"

import lodash from "lodash"
import deepdash from "deepdash"
import { lang } from "config"
import Label from "components/forms/label"
import Input from "components/forms/input"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import InputImage from "components/forms/input-image"
// import { convertToRaw } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import draftToHtml from 'draftjs-to-html';
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Heading, List, Font } from 'ckeditor5';
// import { SlashCommand } from 'ckeditor5-premium-features';

import 'ckeditor5/ckeditor5.css';
import Checkbox from "components/forms/checkbox"

export default function Form({ data, errors, onSubmit }) {
    const _ = deepdash(lodash)

    const id = data?.id
    const [value, set_value] = useState(data?.value ?? null)
    const [type, set_type] = useState(data?.type ?? null)

    const handleSubmit = (e) => {
        e.preventDefault()

        if(type === null || type === ''){
            toast.error("Type Required!")
        }else if(value === null || value === ''){
            toast.error("Value Required!")
        }else{
            if (!nProgress.isStarted()) {
                onSubmit({
                    id,
                    type,
                    value,
                })
            } else {
                toast.error("Previous action is loading")
            }
        }
    }

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <Label htmlFor="type" value="Type" />
                                        <select onChange={(e) => set_type(e.target.value)} value={type} name="type" id="type" className={`border-neutral-200 block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                            <option value={null} selected disabled>-- {lang.choose} Type --</option>
                                                {/* <option value={'Chat'}>{'Chat'}</option> */}
                                                <option value={'Email'}>{'Email'}</option>
                                                <option value={'WhatsApp'}>{'WhatsApp'}</option>
                                        </select>
                                    </div>
                                    <div>
                                        <Label htmlFor="value" value={'Value'} />
                                        <Input placeholder="Input Value" onChange={(e) => set_value(e.target.value)} value={value} id="value" error={errors.name} />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>

            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${_.isEmpty(data) ? lang.create : lang.update}`}</span>
                </button>
            </div>
        </form>
    )
}